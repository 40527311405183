<script setup lang="ts">
import { useModal } from '~/stores/modal'
import { useAuth } from '@/stores/authentication'
import constants from '@/config/constants'

const Router = useRouter()

const {$facebookAuth} = useNuxtApp()

const { FACEBOOK_CALLBACK } = useAuthService()

onMounted(async () => {
    await $facebookAuth.load()
    await $facebookAuth.init()
});

const ModalStore = useModal()

const errorHandler = () => {
    ModalStore.setModal({
        name: 'ModalDefault',
        info: {
            text: 'No pudo realizar la autenticación con Facebook.',
            continue: {
                handler: () => null,
                text: 'De acuerdo',
            },
        },
    })
};

const authenticate = async () => {
    const FB = $facebookAuth.getFacebookObject();

    FB.login((res) => {
        if(res.authResponse.accessToken){
            FACEBOOK_CALLBACK({access_token: res.authResponse.accessToken}).then(response => {
                if(!response){
                    errorHandler();
                    return;
                }

                const AuthStore = useAuth()

                AuthStore.setAuthData({
                    profile: response.user,
                    token: response.access_token,
                    status: true,
                })

                const now = new Date()

                const expirationDate = new Date(new Date().setFullYear(now.getFullYear() + 1))

                const tokenCookie = useCookie(constants.authToken, {
                    expires: expirationDate,
                    path: '/',
                })

                tokenCookie.value = response.access_token

                Router.push('/');
            });
        }else{
            errorHandler();
        }
    }, { scope: 'email' });
};
</script>

<template>
    <button type="button" @click="authenticate">
        <img :src="$assets.icons.facebook" />
        <span>Inicia sesión con Facebook</span>
    </button>
</template>