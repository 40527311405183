<script setup lang="ts">
    const {$googleAuth} = useNuxtApp()

    const authenticate = async () => {
        await $googleAuth.signin()
    };
</script>

<template>
    <button type="button" @click="authenticate">
        <img :src="$assets.icons.google" />
        <span>Inicia sesión con Google</span>
    </button>
</template>